import React from "react";

import { Link } from "gatsby";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import useOnboardingForClient from "@/hooks/useOnboardingForClient";
import { marketingMainPage5, marketingOnboardingAnswers } from "@/marketing/utils";
import "./styles.scss";

const VideoCarousel = () => {
  const { isActiveOnboarding, linkForOpenOnboarding } = useOnboardingForClient();

  return (
    <div className="video-carousel">
      <div className="page-width">
        <h2 className="h1 main-page__title">Наши эксперты</h2>
        <div className="getreview-widget" data-widget-id="5cXLPAdb4tQecCnJ" />
        <div className="video-carousel__btn">
          <Link onClick={() => {
            marketingMainPage5()
            if (isActiveOnboarding) {
              marketingOnboardingAnswers("step-0", "The onboarding modal was opened")
            }
          }} to={isActiveOnboarding ? linkForOpenOnboarding : `/${CATALOG_PAGE_NAME}`}>
            <Button
              text={isActiveOnboarding ? "Подобрать таролога" : "Выбрать таролога"}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VideoCarousel;
