import { useRef } from "react";
import * as React from "react";

import star from "@images/main/star_small.svg";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import useOnboardingForClient from "@/hooks/useOnboardingForClient";
import { marketingMainPage2, marketingOnboardingAnswers } from "@/marketing/utils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import { CATALOG_PAGE_NAME } from "@components/constants";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/effect-fade";
import "./styles.scss";

import Slides, { images } from "./constants";

const Questions = () => {
  const paginationHighlighter = useRef(null);
  const { isActiveOnboarding, linkForOpenOnboarding } = useOnboardingForClient();

  return (
    <div className="main-page__item questions">
      <div className="page-width questions__inner">
        {images.map((item) =>
        (
          <LazyLoad key={item.default} offset={200}>
            <img
              src={item.default}
              srcSet={item.srcSet}
              alt={item.alt}
              className={item.className}
            />
          </LazyLoad>
        ))}
        <h2 className="h1 main-page__title questions__title">С чем помогут тарологи</h2>
        <div className="questions__slider-container">
          {/* <div className="questions__pagination" /> */}
          <img src={star} alt="" className="questions__slider-star" />
          <img src={star} alt="" className="questions__slider-star --second" />
          <div className="questions__slider-btn questions__slider-prev">
            <Icon type={IconTypeEnum.Left} size={IconSizeEnum.Size32} />
          </div>
          <div className="questions__slider-btn questions__slider-next">
            <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size32} />
          </div>
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={20}
            loop
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            allowTouchMove={false}
            pagination={{
              el: ".questions__pagination",
              renderBullet(index, className) {
                return `<div class="${className} questions__slide-title">
                          <span>${Slides[index].title}</span>
                        </div>`;
              },
              clickable: true,
            }}
            navigation={{
              nextEl: ".questions__slider-next",
              prevEl: ".questions__slider-prev",
            }}
            onSlideChange={(swiper) => {
              if (paginationHighlighter.current) {
                (paginationHighlighter.current as HTMLDivElement).style.top = `${swiper.snapIndex * (49 + 12)
                  }px`;
              }
            }}
            className="questions__slider"
            breakpoints={{
              769: {
                loop: false,
                direction: "vertical",
              },
            }}
          >
            {Slides.map((item) =>
            (
              <SwiperSlide key={item.title} className="questions__slide">
                <div className="questions__slide-data">
                  <div className="questions__slide-title">
                    <span>{item.title}</span>
                  </div>
                  <ul>
                    {item.questionList.map((question) =>
                    (
                      <li key={question}>{question}</li>
                    ))}
                  </ul>
                </div>
              </SwiperSlide>
            ))}
            <div slot="container-start" className="questions__pagination-container">
              <div ref={paginationHighlighter} className="questions__pagination-highlighter" />
              <div className="questions__pagination" />
            </div>
          </Swiper>
        </div>
        <div className="questions__btn">
          <Link onClick={() => {
            marketingMainPage2()
            if (isActiveOnboarding) {
              marketingOnboardingAnswers("step-0", "The onboarding modal was opened")
            }
          }} to={isActiveOnboarding ? linkForOpenOnboarding : `/${CATALOG_PAGE_NAME}`}>
            <Button
              text="Задать свой вопрос"
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Questions;
