import * as React from "react";

import { Link } from "gatsby";

import CardBlock from "@/components/common/cardBlock";
import useOnboardingForClient from "@/hooks/useOnboardingForClient";
import { marketingMainPage5, marketingOnboardingAnswers } from "@/marketing/utils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { CATALOG_PAGE_NAME } from "@components/constants";

import { CONSULTATION_INFO_DATA } from "./constants";

import "./styles.scss";

const HowIsTaro = () => {
  const { isActiveOnboarding, linkForOpenOnboarding } = useOnboardingForClient();

  return (
    <div className="main-page__item how-is-taro">
      <div className="page-width how-is-taro__inner">
        <CardBlock
          header={CONSULTATION_INFO_DATA.header}
          items={CONSULTATION_INFO_DATA.items}
        />
        <div className="how-is-taro__btn">
          <Link onClick={() => {
            marketingMainPage5()
            if (isActiveOnboarding) {
              marketingOnboardingAnswers("step-0", "The onboarding modal was opened")
            }
          }} to={isActiveOnboarding ? linkForOpenOnboarding : `/${CATALOG_PAGE_NAME}`}>
            <Button
              text={isActiveOnboarding ? "Подобрать таролога" : "Выбрать таролога"}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowIsTaro;
